import React, { Component } from 'react';

import StorageBackgroundVideo from '../../assets/videos/storage.mp4';

class Storage extends Component {

  constructor(props) {
    super(props);

    this.props = props;
  }

  render() {
    const { content } = this.props;

    return (
      <section className="ga-section ga-section--storage">

        <div className="ga-section__wrap ga-section__wrap--left">

          <div className="ga-section__body">

            <h2 className="ga-section__title" dangerouslySetInnerHTML={{ __html: content.title.rendered }} />

            <div className="ga-section__content" dangerouslySetInnerHTML={{ __html: content.content.rendered }} />

          </div>

          <div className="ga-section__visual">
            {/* <img className="ga-section__visual__image ga-section__visual__image--stretch" src={GreenCloud} alt="Green Cloud" /> */}
            <video autoPlay muted loop id="gaHighlightVideo" className="ga-section__visual__video">
              <source src={`${StorageBackgroundVideo}#t=1`} type="video/mp4" />
            </video>
          </div>

        </div>

      </section>
    );
  }
};

export default Storage;
