import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios'

import { metadata } from '../../constants/metadata';

import Highlight from '../highlight';
import Services from '../services';
import Storage from '../storage';
import Extra from '../extra';
import Responsibility from '../responsibility';
import Contact from '../contact';

class HomePage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      sections: {
        highlight: {},
        services: {},
        storage: {},
        extra: {},
        responsibility: {},
        inquiry: {},
        contact: {},
      }
    }
  }

  componentDidMount() {
    this.fetchContent();
  }

  componentDidUpdate(prevProps) {
    if(prevProps.local !== this.props.local) {
      this.fetchContent();
    }
  }

  fetchContent = async () => {
    try {
      const categories = await axios.get(`https://acconsulting.hu/wp/wp-json/wp/v2/categories/?per_page=100`)
        .then(res => res.data)
      const sections = await axios.get(`https://acconsulting.hu/wp/wp-json/wp/v2/posts/?per_page=100`)
        .then(res => res.data.filter(p => p.categories.some(e => e === categories.find(c => c.slug === this.props.local).id)))
      this.setState({
        loading: false,
        sections: {
          highlight: sections.find(s => s.categories.some(e => e === categories.find(c => c.slug === 'slogan').id)),
          services: sections.find(s => s.categories.some(e => e === categories.find(c => c.slug === 'services').id)),
          storage: sections.find(s => s.categories.some(e => e === categories.find(c => c.slug === 'storage').id)),
          extra: sections.find(s => s.categories.some(e => e === categories.find(c => c.slug === 'extra').id)),
          responsibility: sections.find(s => s.categories.some(e => e === categories.find(c => c.slug === 'social-responsibility').id)),
          inquiry: sections.find(s => s.categories.some(e => e === categories.find(c => c.slug === 'inquiry').id)),
          contact: sections.find(s => s.categories.some(e => e === categories.find(c => c.slug === 'contact').id)),
        }
      });
    }
    catch(err) {
      alert(err);
    }
  }

  render() {
    const { loading, sections } = this.state;

    if (!loading) {

      return (
        <>
          <Helmet>
            <title>{metadata[this.props.local].title}</title>
            <meta name="description" content={metadata[this.props.local].description} />
            <meta name="keywords" cpntent={metadata[this.props.local].keywords} />
          </Helmet>
          <main>
            <Highlight content={sections.highlight} />
            <Services content={sections.services} />
            <Storage content={sections.storage} />
            <Extra content={sections.extra} />
            <Responsibility content={sections.responsibility} />
            <Contact content={{detail: sections.contact, inquiry: sections.inquiry}} local={this.props.local} />
          </main>
        </>
      );
    }

    return (
      <div id="ga-progress-indicator" className="ga-loader">
        <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
      </div>
    )
  }
};

export default HomePage;
