import React, { Component } from 'react';

class NotFound extends Component {

  render() {

    return (
      <div>
        Not Found
      </div>
    );
  }
};

export default NotFound;
