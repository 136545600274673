import React, { Component } from 'react';

import GreenScreen from '../../assets/images/green-macbook.png';

class Services extends Component {

  constructor(props) {
    super(props);

    this.props = props;
  }

  render() {
    const { content } = this.props;

    return (
      <section className="ga-section ga-section--services">

        <div className="ga-section__wrap ga-section__wrap--right">

          <div className="ga-section__visual">
            <img className="ga-section__visual__image" src={GreenScreen} alt="Green Screen" />
          </div>

          <div className="ga-section__body">

            <h2 className="ga-section__title" dangerouslySetInnerHTML={{ __html: content.title.rendered }} />

            <div className="ga-section__content" dangerouslySetInnerHTML={{ __html: content.content.rendered }} />

          </div>

        </div>

      </section>
    );
  }
};

export default Services;
