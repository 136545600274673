import React, { Component } from 'react';

class Extra extends Component {

  constructor(props) {
    super(props);

    this.props = props;
  }

  render() {
    const { content } = this.props;

    return (
      <section className="ga-section ga-section--extra">

        <div className="ga-section__wrap ga-section__wrap--center">

          <div className="ga-section__body">

            <div className="ga-section__content" dangerouslySetInnerHTML={{ __html: content.content.rendered }} />

          </div>

        </div>

      </section>
    );
  }
};

export default Extra;
