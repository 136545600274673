import React from 'react';
import { Link } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';

import GreenAccountingLogo from '../../assets/images/acconsulting.svg';

const Header = () => (
  <>
    <div className="ga-header">
      <div className="ga-header__logo">
        <img className="ga-header__logo_svg" style={{display: 'none'}} src={GreenAccountingLogo} alt="Acconsulting" />
        <svg className="ga-header__logo__svg" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 1000 1000" style="enable-background:new 0 0 1000 1000;" xml:space="preserve">
<style type="text/css">
	.st0{display:none;}
	.st1{display:inline;fill:#44B871;}
	.st2{display:inline;fill:#2D3F3A;}
	.st3{fill:#44B871;}
	.st4{display:none;opacity:0.5;}
	.st5{display:none;opacity:0.5;fill:#44B871;}
	.st6{display:none;fill:#44B871;}
</style>
<g id="Layer_1">
	<g class="st0">
		<path class="st1" d="M284.1,626.9L257,641.2c-6.5-8.6-16.6-13.4-31.5-13.4c-22.7,0-38.6,16.5-38.6,39.1
			c0,21.5,16.2,40.1,38.9,40.1c13.9,0,25-4.6,31.9-11.4v-13.4h-41.5v-24.9h69.2v50.2c-1,2-21.4,29.9-59.4,29.9
			c-43,0-70.6-33.7-70.6-70.2c0-37.7,29.6-70.2,70.4-70.2C250.9,597.2,271.1,608.9,284.1,626.9z"/>
		<path class="st1" d="M426,650.1c0,19.5-9.7,36.1-24.5,43.9l28.4,40.9h-42.7l-20-33.2h-23.6v33.2h-33.2V599.2H377
			C404.9,599.2,426,620.6,426,650.1z M343.5,629.7v41.5h28.3c11.2,0,20.3-9.4,20.3-20.9c0-11.2-9-20.7-20.1-20.7H343.5z"/>
		<path class="st1" d="M556.7,599.2v30.5h-72v24.9h58.1v27.7h-58.1v22.1h72v30.5H451.5V599.2H556.7z"/>
		<path class="st1" d="M686.8,599.2v30.5h-72v24.9H673v27.7h-58.1v22.1h72v30.5H581.6V599.2H686.8z"/>
		<path class="st1" d="M844.6,734.9h-30.4l-69.3-83v83h-33.2V599.2h30.4l69.3,82.8v-82.8h33.2V734.9z"/>
	</g>
	<g class="st0">
		<path class="st2" d="M215.6,835.1l-7.3-18.1h-38.9l-7.3,18.1h-7.3l30.1-73.6h7.8l30.1,73.6H215.6z M188.8,768.3l-17.3,43h34.7
			L188.8,768.3z"/>
		<path class="st2" d="M268.3,836.5c-5.2,0-10.1-0.9-14.6-2.8c-4.5-1.8-8.5-4.4-11.9-7.8c-3.4-3.3-6.1-7.4-8-12
			c-1.9-4.7-2.9-9.8-2.9-15.5c0-5.7,1-10.8,2.9-15.5c1.9-4.7,4.6-8.7,8-12c3.4-3.3,7.3-5.9,11.9-7.8c4.5-1.8,9.4-2.8,14.6-2.8
			c5.9,0,11,1.2,15.4,3.5c4.4,2.4,8.2,5.5,11.4,9.5l-5.3,3.4c-2.4-3.2-5.4-5.8-9.2-7.8c-3.8-1.9-7.9-2.9-12.3-2.9
			c-4.3,0-8.4,0.8-12.1,2.4c-3.7,1.6-6.9,3.8-9.7,6.6c-2.8,2.8-4.9,6.2-6.5,10.2c-1.6,4-2.4,8.3-2.4,13.1c0,4.8,0.8,9.1,2.4,13.1
			c1.6,3.9,3.7,7.3,6.5,10.2c2.8,2.9,6,5.1,9.7,6.7c3.7,1.6,7.7,2.4,12.1,2.4c4.4,0,8.5-1,12.3-2.9c3.8-1.9,6.9-4.5,9.2-7.8l5.3,3.3
			c-3.3,4-7.1,7.2-11.5,9.6C279.3,835.3,274.2,836.5,268.3,836.5z"/>
		<path class="st2" d="M342.4,836.5c-5.2,0-10.1-0.9-14.6-2.8c-4.5-1.8-8.5-4.4-11.9-7.8c-3.4-3.3-6.1-7.4-8-12
			c-1.9-4.7-2.9-9.8-2.9-15.5c0-5.7,1-10.8,2.9-15.5c1.9-4.7,4.6-8.7,8-12c3.4-3.3,7.3-5.9,11.9-7.8c4.5-1.8,9.4-2.8,14.6-2.8
			c5.9,0,11,1.2,15.4,3.5c4.4,2.4,8.2,5.5,11.4,9.5l-5.3,3.4c-2.4-3.2-5.4-5.8-9.2-7.8c-3.8-1.9-7.9-2.9-12.3-2.9
			c-4.3,0-8.4,0.8-12.1,2.4c-3.7,1.6-6.9,3.8-9.7,6.6c-2.8,2.8-4.9,6.2-6.5,10.2c-1.6,4-2.4,8.3-2.4,13.1c0,4.8,0.8,9.1,2.4,13.1
			c1.6,3.9,3.7,7.3,6.5,10.2c2.8,2.9,6,5.1,9.7,6.7c3.7,1.6,7.7,2.4,12.1,2.4c4.4,0,8.5-1,12.3-2.9c3.8-1.9,6.9-4.5,9.2-7.8l5.3,3.3
			c-3.3,4-7.1,7.2-11.5,9.6C353.4,835.3,348.3,836.5,342.4,836.5z"/>
		<path class="st2" d="M414.9,836.5c-5.4,0-10.4-1-14.7-2.9c-4.4-1.9-8.1-4.6-11.3-8.1c-3.1-3.4-5.5-7.4-7.2-12.1
			c-1.7-4.6-2.5-9.6-2.5-15c0-5.4,0.8-10.4,2.5-15c1.7-4.6,4.1-8.7,7.2-12.1c3.1-3.4,6.9-6.1,11.3-8.1c4.4-1.9,9.3-2.9,14.7-2.9
			c5.4,0,10.3,1,14.7,2.9s8.2,4.6,11.3,8.1c3.1,3.4,5.5,7.4,7.2,12.1c1.7,4.6,2.5,9.6,2.5,15c0,5.4-0.8,10.4-2.5,15
			c-1.7,4.6-4.1,8.7-7.2,12.1c-3.1,3.4-6.9,6.1-11.3,8.1C425.2,835.5,420.3,836.5,414.9,836.5z M414.9,830.7c4.4,0,8.4-0.8,11.9-2.4
			c3.6-1.6,6.6-3.9,9.1-6.8c2.5-2.9,4.5-6.3,5.9-10.3c1.4-3.9,2.1-8.2,2.1-12.8c0-4.7-0.7-9-2.1-13c-1.4-3.9-3.4-7.3-5.9-10.2
			c-2.5-2.9-5.6-5.1-9.1-6.7c-3.6-1.6-7.5-2.4-11.9-2.4c-4.5,0-8.5,0.8-12.1,2.4c-3.6,1.6-6.6,3.9-9.1,6.7
			c-2.5,2.9-4.5,6.3-5.8,10.2c-1.4,3.9-2,8.3-2,13c0,4.6,0.7,8.9,2,12.8c1.4,3.9,3.3,7.4,5.8,10.3c2.5,2.9,5.6,5.2,9.1,6.8
			C406.4,829.9,410.4,830.7,414.9,830.7z"/>
		<path class="st2" d="M494.8,836.5c-4.7,0-8.8-0.7-12.4-2.1c-3.6-1.4-6.5-3.4-9-5.9c-2.4-2.5-4.2-5.6-5.4-9.3
			c-1.2-3.6-1.8-7.7-1.8-12.3v-45.3h6.3v45.2c0,7.5,1.9,13.4,5.7,17.6s9.3,6.3,16.6,6.3c7.4,0,12.9-2.1,16.7-6.3
			c3.8-4.2,5.6-10.1,5.6-17.6v-45.2h6.3v45.3c0,9.1-2.4,16.3-7.2,21.6C511.4,833.8,504.2,836.5,494.8,836.5z"/>
		<path class="st2" d="M594.4,835.1l-46.3-63.2v63.2h-6.3v-73.6h6.4l46.1,62.3v-62.3h6.3v73.6H594.4z"/>
		<path class="st2" d="M637.6,835.1v-67.8h-24v-5.7H668v5.7h-24v67.8H637.6z"/>
		<path class="st2" d="M681.1,835.1v-73.6h6.3v73.6H681.1z"/>
		<path class="st2" d="M758.3,835.1L712,771.9v63.2h-6.3v-73.6h6.4l46.1,62.3v-62.3h6.3v73.6H758.3z"/>
		<path class="st2" d="M817.3,836.6c-5.1,0-10-0.9-14.5-2.8c-4.5-1.8-8.5-4.4-11.9-7.8c-3.4-3.3-6-7.4-8-12.1
			c-1.9-4.7-2.9-9.9-2.9-15.6c0-5.7,1-10.8,3-15.5c2-4.7,4.7-8.7,8.1-12c3.4-3.3,7.3-5.9,11.9-7.8c4.5-1.8,9.4-2.8,14.5-2.8
			c6.1,0,11.4,1.1,15.9,3.4c4.5,2.2,8.4,5.3,11.8,9.2l-4.7,3.4c-2.6-3.1-5.9-5.6-9.9-7.4c-4-1.9-8.4-2.8-13.1-2.8
			c-4.3,0-8.4,0.8-12.1,2.4c-3.7,1.6-6.9,3.8-9.7,6.6c-2.8,2.8-4.9,6.2-6.5,10.2c-1.6,4-2.4,8.3-2.4,13.1c0,4.8,0.8,9.2,2.4,13.1
			c1.6,4,3.7,7.4,6.5,10.3c2.7,2.9,6,5.1,9.7,6.7c3.7,1.6,7.7,2.4,12.1,2.4c2.4,0,4.7-0.3,6.9-0.8c2.2-0.6,4.2-1.2,6-2.1
			c1.8-0.8,3.4-1.8,4.9-2.9c1.5-1.1,2.7-2.1,3.7-3.1v-17.6h-27.8v-5.7h34.1v25.8c-3.3,3.7-7.3,6.7-12.1,8.9
			C828.4,835.4,823.1,836.6,817.3,836.6z"/>
	</g>
	<g id="Layer_3">
		<g>
			<path class="st3" d="M154,764.4l25.1-61.6l25.1,61.6h-9.2l-1.8-4.9h-28.1l-1.9,4.9H154z M168.2,751.7h22l-11-28.2L168.2,751.7z"
				/>
			<path class="st3" d="M248,756c3.6,0,6.9-0.9,9.9-2.6c3-1.7,5.4-4,7.4-6.8l7.3,5.5c-2.8,3.8-6.3,6.8-10.5,9.1
				c-4.2,2.2-8.9,3.4-14,3.4c-4.2,0-8.1-0.8-11.8-2.4c-3.7-1.6-6.9-3.8-9.7-6.6c-2.7-2.8-4.9-6-6.5-9.7c-1.6-3.7-2.4-7.7-2.4-12
				c0-4.3,0.8-8.2,2.4-12c1.6-3.7,3.8-7,6.5-9.7c2.7-2.8,6-5,9.7-6.6c3.7-1.6,7.6-2.4,11.8-2.4c5.1,0,9.8,1.1,14,3.4
				c4.2,2.2,7.7,5.3,10.5,9.1l-7.3,5.5c-1.9-2.9-4.4-5.1-7.4-6.8c-3-1.7-6.3-2.6-9.9-2.6c-2.9,0-5.6,0.6-8.2,1.8
				c-2.5,1.2-4.8,2.8-6.7,4.8c-1.9,2-3.4,4.4-4.5,7.1c-1.1,2.7-1.7,5.6-1.7,8.7c0,3.1,0.6,6,1.7,8.7c1.1,2.7,2.6,5,4.5,7.1
				c1.9,2,4.1,3.6,6.7,4.8C242.3,755.5,245,756,248,756z"/>
			<path class="st3" d="M316.2,756c3.6,0,6.9-0.9,9.9-2.6c3-1.7,5.4-4,7.4-6.8l7.3,5.5c-2.8,3.8-6.3,6.8-10.5,9.1
				c-4.2,2.2-8.9,3.4-14,3.4c-4.2,0-8.1-0.8-11.8-2.4c-3.7-1.6-6.9-3.8-9.7-6.6c-2.7-2.8-4.9-6-6.5-9.7c-1.6-3.7-2.4-7.7-2.4-12
				c0-4.3,0.8-8.2,2.4-12c1.6-3.7,3.8-7,6.5-9.7c2.7-2.8,6-5,9.7-6.6c3.7-1.6,7.6-2.4,11.8-2.4c5.1,0,9.8,1.1,14,3.4
				c4.2,2.2,7.7,5.3,10.5,9.1l-7.3,5.5c-1.9-2.9-4.4-5.1-7.4-6.8c-3-1.7-6.3-2.6-9.9-2.6c-2.9,0-5.6,0.6-8.2,1.8
				c-2.5,1.2-4.8,2.8-6.7,4.8c-1.9,2-3.4,4.4-4.5,7.1c-1.1,2.7-1.7,5.6-1.7,8.7c0,3.1,0.6,6,1.7,8.7c1.1,2.7,2.6,5,4.5,7.1
				c1.9,2,4.1,3.6,6.7,4.8C310.5,755.5,313.2,756,316.2,756z"/>
			<path class="st3" d="M384.6,702.7c4.3,0,8.3,0.8,12,2.4c3.8,1.6,7,3.8,9.8,6.6c2.8,2.8,5,6.1,6.6,9.9s2.4,7.8,2.4,12.1
				c0,4.3-0.8,8.3-2.4,12c-1.6,3.8-3.8,7-6.6,9.8c-2.8,2.8-6,5-9.8,6.6c-3.8,1.6-7.8,2.4-12,2.4c-4.3,0-8.2-0.8-12-2.4
				c-3.7-1.6-7-3.8-9.7-6.6c-2.8-2.8-5-6.1-6.6-9.8c-1.6-3.8-2.4-7.8-2.4-12c0-4.3,0.8-8.4,2.4-12.1c1.6-3.8,3.8-7.1,6.6-9.9
				c2.8-2.8,6-5,9.7-6.6C376.4,703.5,380.4,702.7,384.6,702.7z M384.6,756c2.9,0,5.7-0.6,8.3-1.8c2.6-1.2,4.9-2.8,6.8-4.8
				c1.9-2,3.5-4.4,4.6-7.1c1.1-2.7,1.7-5.6,1.7-8.7s-0.6-6-1.7-8.7c-1.1-2.7-2.6-5.1-4.6-7.2s-4.2-3.6-6.8-4.8
				c-2.6-1.2-5.4-1.8-8.3-1.8c-2.9,0-5.7,0.6-8.2,1.8c-2.6,1.2-4.8,2.8-6.8,4.8c-1.9,2-3.5,4.4-4.6,7.2c-1.1,2.7-1.7,5.6-1.7,8.7
				s0.6,6,1.7,8.7c1.1,2.7,2.6,5.1,4.6,7.1c1.9,2,4.2,3.6,6.8,4.8C379,755.5,381.7,756,384.6,756z"/>
			<path class="st3" d="M437.1,764.7h-8.5V702l39.8,47.6V703h8.5v61.7h-8.2l-31.6-38.9V764.7z"/>
			<path class="st3" d="M524,733.9c0.4,0.3,0.8,0.6,1.1,1c0.3,0.4,0.6,0.7,0.9,1.1c1.3,1.6,2.3,3.4,3,5.3c0.7,1.9,1.1,4,1.1,6.1
				c0,0.5,0,0.9-0.1,1.2c-0.2,3.1-1.1,6-2.9,8.5c-1.6,2.4-3.6,4.2-6,5.6s-5.2,2.1-8.2,2.1h-5.5c-3,0-5.8-0.7-8.2-2.1s-4.5-3.2-6-5.6
				c-0.4-0.7-0.9-1.5-1.3-2.4l7.8-6c0.2,1.1,0.5,2.1,1.1,3c0.5,0.9,1.2,1.7,2,2.4c0.8,0.7,1.7,1.2,2.7,1.6c1,0.4,2.1,0.6,3.2,0.6
				h3.4c2.3,0,4.3-0.7,6-2.2c1.7-1.5,2.7-3.3,3-5.5c0-0.2,0-0.4,0-0.7c0-0.2,0-0.4,0-0.7c0-1.2-0.2-2.4-0.7-3.4
				c-0.4-1.1-1.1-2-1.8-2.8c-0.8-0.8-1.7-1.5-2.8-2c-1.1-0.5-2.2-0.8-3.4-0.8h-4.8h-0.3c-2.1-0.1-4-0.5-5.8-1.2
				c-1.8-0.8-3.5-1.8-4.9-3.1c-0.8-0.6-1.5-1.2-2-2c-1.3-1.6-2.3-3.4-3-5.3c-0.7-1.9-1.1-4-1.1-6.1v-1.2c0.3-3.2,1.3-6,2.9-8.5
				c1.6-2.4,3.6-4.2,6-5.6c2.5-1.4,5.2-2.1,8.2-2.1h5.5c3,0,5.8,0.7,8.3,2.1c2.5,1.4,4.5,3.2,6.1,5.6c0.2,0.3,0.3,0.6,0.5,0.9
				c0.2,0.3,0.4,0.6,0.5,0.9l-7.6,6c-0.5-2-1.6-3.7-3.2-5c-1.6-1.3-3.5-2-5.6-2h-3.4c-1.1,0-2.2,0.2-3.2,0.6c-1,0.4-1.9,0.9-2.7,1.6
				c-0.8,0.7-1.4,1.5-2,2.5c-0.5,1-0.9,2-1.1,3c-0.1,0.2-0.1,0.4-0.1,0.7c0,0.2,0,0.4,0,0.7c0,1.2,0.2,2.4,0.7,3.4
				c0.4,1.1,1.1,2,1.8,2.8c0.8,0.8,1.7,1.5,2.8,2c1.1,0.5,2.2,0.8,3.4,0.8h4.8h0.3c2.1,0.1,4,0.5,5.8,1.2
				C520.9,731.6,522.5,732.6,524,733.9z"/>
			<path class="st3" d="M576.1,703h8.8v41.2c0,2.9-0.5,5.5-1.6,8c-1.1,2.5-2.6,4.6-4.5,6.4c-1.9,1.8-4.1,3.3-6.6,4.3
				c-2.5,1.1-5.2,1.6-8.1,1.6c-2.9,0-5.5-0.5-8-1.6c-2.5-1.1-4.7-2.5-6.6-4.3c-1.9-1.8-3.3-4-4.4-6.4c-1.1-2.5-1.6-5.1-1.6-8V703
				h8.8v41.2c0,1.6,0.3,3.2,0.9,4.6c0.6,1.4,1.5,2.7,2.5,3.8c1.1,1.1,2.3,1.9,3.8,2.6c1.5,0.6,3,0.9,4.6,0.9c1.7,0,3.2-0.3,4.7-0.9
				c1.5-0.6,2.7-1.5,3.8-2.6c1.1-1.1,1.9-2.4,2.6-3.8s0.9-3,0.9-4.6V703z"/>
			<path class="st3" d="M607.1,755.8h25.1v8.8h-34.1V703h9V755.8z"/>
			<path class="st3" d="M645.5,703h39.3v8.7h-15.2v52.8h-8.9v-52.8h-15.2V703z"/>
			<path class="st3" d="M698.1,703h9v61.6h-9V703z"/>
			<path class="st3" d="M728.8,764.7h-8.5V702l39.8,47.6V703h8.5v61.7h-8.2l-31.6-38.9V764.7z"/>
			<path class="st3" d="M842.8,732.1c0,0.6,0,1.1,0,1.7c0,0.8,0,1.5-0.1,2.3c-0.1,0.7-0.1,1.5-0.3,2.2c-0.5,3.7-1.6,7.2-3.4,10.4
				c-1.8,3.2-4,6-6.6,8.4c-2.7,2.4-5.7,4.2-9.1,5.5c-3.4,1.3-7.1,2-10.9,2c-4.2,0-8.2-0.8-11.9-2.4c-3.7-1.6-7-3.8-9.7-6.6
				c-2.7-2.8-4.9-6-6.5-9.7c-1.6-3.7-2.4-7.7-2.4-12c0-4.3,0.8-8.2,2.4-12c1.6-3.7,3.8-7,6.5-9.7c2.7-2.8,6-5,9.7-6.6
				c3.7-1.6,7.7-2.4,11.9-2.4c3.2,0,6.2,0.5,9.1,1.4c2.9,0.9,5.5,2.2,7.9,3.9c2.4,1.7,4.5,3.7,6.4,6c1.9,2.3,3.4,4.9,4.6,7.6h-10.3
				c-1.8-3.1-4.3-5.7-7.4-7.6c-3.1-1.9-6.5-2.9-10.3-2.9c-2.9,0-5.7,0.6-8.2,1.8c-2.6,1.2-4.8,2.8-6.7,4.8c-1.9,2-3.4,4.4-4.5,7.1
				c-1.1,2.7-1.7,5.6-1.7,8.7c0,3.1,0.6,6,1.7,8.7c1.1,2.7,2.6,5,4.5,7.1c1.9,2,4.1,3.6,6.7,4.8c2.6,1.2,5.3,1.8,8.2,1.8
				c2.5,0,4.9-0.4,7.2-1.3c2.3-0.9,4.4-2.1,6.2-3.7c1.8-1.6,3.4-3.5,4.6-5.7c1.3-2.2,2.1-4.5,2.6-7.1h-21.5v-7.9h21.7h9.4
				C842.7,731,842.8,731.5,842.8,732.1z"/>
		</g>
	</g>
	<g class="st4">
		<path class="st1" d="M455.5,235v27.6H213.8v202.3h214.1v-89.6H307v-27.6h148.4v144.8H186.2V235H455.5 M487,203.4h-31.6H186.2
			h-31.6V235v257.5v31.6h31.6h269.3H487v-31.6V347.8v-31.6h-31.6H307h-31.6v31.6v27.6v31.6H307h89.3v26.5h-151V294.2h210.1H487
			v-31.6V235V203.4L487,203.4z"/>
	</g>
	<path class="st5" d="M813.5,235v140.3v115h-27.6v-115H571.8v117.2h-27.6V235h2.4H813.5 M571.8,347.8h214.1v-85.2H571.8V347.8
		 M845.1,203.4h-31.6H546.6h-2.4h-31.6V235v257.5v31.6h31.6h27.6h31.6v-31.6v-85.6h151v83.4v31.6h31.6h27.6h31.6v-31.6v-115V235
		V203.4L845.1,203.4z M603.4,294.2h151v22h-151V294.2L603.4,294.2z"/>
	<polygon class="st6" points="487,524.1 154.6,524.1 154.6,203.4 487,203.4 487,262.6 205.8,262.6 205.8,473.1 320.8,473.1 
		320.8,363.8 487,363.8 	"/>
	<polygon class="st6" points="845.1,521.9 785.9,521.9 785.2,362.7 571,363.8 571.8,521.9 512.7,521.9 512.7,203.4 845.1,203.4 	"/>
	<polygon class="st3" points="536.5,254.4 536.5,637.3 842.8,637.2 842.8,407.6 689.7,407.6 689.6,484.2 766.1,484.1 766.2,560.7 
		613,560.8 613,331 842.8,331.1 842.8,254.4 	"/>
	<polygon class="st6" points="155.5,163.4 155.5,546.3 461.8,546.2 461.8,316.6 308.7,316.6 308.6,393.2 385.1,393.1 385.2,469.7 
		232,469.8 232,240 461.8,240.1 461.8,163.4 	"/>
	<polygon class="st3" points="459.5,254.4 459.5,637.3 153.2,637.2 153.2,407.6 306.3,407.6 306.4,484.2 229.9,484.1 229.8,560.7 
		383,560.8 383,331 153.2,331.1 153.2,254.4 	"/>
</g>
<g id="Layer_2">
</g>
</svg>
      </div>
      <div className="ga-header__nav">
        <ul>
          <li>
            <Link to={ROUTES.HOME}>EN</Link>
          </li>
          <li>
            <Link to={ROUTES.HOME_HU}>HU</Link>
          </li>
        </ul>
      </div>
    </div>
  </>
);

export default Header;