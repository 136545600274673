import React, { Component } from 'react';
import axios from 'axios';

import GreenContact from '../../assets/images/green-contact.jpg';

class Contact extends Component {

  constructor(props) {
    super(props);

    this.props = props;
    this.langString = {
      english: {
        formNameLabel: 'Name:',
        formNamePlaceholder: 'Your name',
        formEmailLabel: 'Email:',
        formEmailPlaceholder: 'Your email',
        formMessageLabel: 'Message:',
        formMessagePlaceholder: 'Your message to us...',
        formButtonSend: 'Send Message',
        formButtonSent: 'Message Sent',
        formButtonSending: '...sending',
        formNotySuccess: 'Your message has been sent!',
        formNotyError: 'There has been an error!',
      },
      hungarian: {
        formNameLabel: 'Név:',
        formNamePlaceholder: 'Neved',
        formEmailLabel: 'Email:',
        formEmailPlaceholder: 'Email címed',
        formMessageLabel: 'Üzenet:',
        formMessagePlaceholder: 'Üzeneted számunkra...',
        formButtonSend: 'Üzenet küldése',
        formButtonSent: 'Üzenet elküldve',
        formButtonSending: '...küldés',
        formNotySuccess: 'Üzenetedet sikeresen elküldted!',
        formNotyError: 'Az üzenet küldése közben hiba történt!',
      }
    }
    this.state = {
      status: 'default',
      name: '',
      message: '',
      email: '',
      sent: false,
      buttonText: this.langString[this.props.local].formButtonSend,
    }
  }

  formSubmit = (e) => {
    e.preventDefault()

    this.setState({
        buttonText: this.langString[this.props.local].formButtonSending
    })

    const data = new FormData(e.target);

    axios.post(`https://acconsulting.hu/mailer/form-handler.php`, data)
    .then(res => {
      this.setState({status: 'sent', sent: true}, this.resetForm())
    })
    .catch((err) => {
      this.setState({status: 'error'})
      console.log('Message not sent')
    })
  }

  resetForm = () => {
    this.setState({
      name: '',
      message: '',
      email: '',
      buttonText: this.langString[this.props.local].formButtonSent
    })
    setTimeout(() => {
      this.setState({
        buttonText: this.langString[this.props.local].formButtonSend
      })
    }, 3000);
  }

  render() {
    const { detail, inquiry } = this.props.content;
    const local = this.props.local;

    return (
      <section className="ga-section ga-section--contact">

        <div className="ga-section__wrap ga-section__wrap--right">

          <div className="ga-section__visual">
            <img className="ga-section__visual__image ga-section__visual__image--stretch" src={GreenContact} alt="Green Contact" />
          </div>

          <div className="ga-section__body">

            <h2 className="ga-section__title" dangerouslySetInnerHTML={{ __html: inquiry.title.rendered }} />

            <div className="ga-section__content" dangerouslySetInnerHTML={{ __html: inquiry.content.rendered }} />

            <div className="ga-form">

              <form
                className="contact-form"
                onSubmit={ (e) => this.formSubmit(e)}>

                <div className="ga-form__fieldset ga-form__fieldset--50">
                    <div className="ga-form__field">
                      <input
                        id="name"
                        name="name"
                        type="text"
                        placeholder={this.langString[local].formNamePlaceholder}
                        value={this.state.name}
                        onChange={e => this.setState({ name: e.target.value})}
                        required />
                      <label htmlFor="name" className="ga-form__label">
                        {this.langString[local].formNameLabel}
                      </label>
                    </div>
                  </div>
                  <div className="ga-form__fieldset ga-form__fieldset--50">
                    <div className="ga-form__field">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        placeholder={this.langString[local].formEmailPlaceholder}
                        value={this.state.email}
                        onChange={(e) => this.setState({ email: e.target.value})}
                        required />
                      <label htmlFor="email" className="ga-form__label">
                        {this.langString[local].formEmailLabel}
                      </label>
                    </div>
                  </div>
                  <div className="ga-form__fieldset ga-form__fieldset--100">
                    <div className="ga-form__field">
                      <textarea
                        id="message"
                        name="message"
                        placeholder={this.langString[local].formMessagePlaceholder}
                        value={this.state.message}
                        onChange={e => this.setState({ message: e.target.value})}
                        required />
                      <label htmlFor="message" className="ga-form__label">
                        {this.langString[local].formMessageLabel}
                      </label>
                    </div>
                  </div>
                  <div className="ga-form__fieldset ga-form__fieldset--50">
                    <div className="ga-form__field--submit">
                      <button type="submit" className="ga-form__submit ga-button ga-button--green">
                        { this.state.buttonText }
                      </button>
                    </div>
                  </div>

                  {this.state.status === `sent` &&
                    <div className="ga-form__noty ga-form__noty--success">{this.langString[local].formNotySuccess}</div>
                  }

                  {this.state.status === `error` &&
                    <div className="ga-form__noty ga-form__noty--error">{this.langString[local].formNotyError}</div>
                  }

              </form>

            </div>

          </div>

          <div className="ga-section__content ga-section__content--detail" dangerouslySetInnerHTML={{ __html: detail.content.rendered }} />

        </div>

      </section>
    );
  }
}

export default Contact;