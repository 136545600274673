import React, { Component } from 'react';

import HighlightBackgroundVideo from '../../assets/videos/highlight.mp4';

class Highlight extends Component {

  constructor(props) {
    super(props);

    this.props = props;
  }

  render() {
    const { content } = this.props;

    return (
      <section className="ga-section ga-section--highlight">

        <div className="ga-highlight">

          <div className="ga-highlight__side"></div>

          <div className="ga-highlight__visual">

            <div className="ga-highlight__bg">
              <video autoPlay muted loop id="gaHighlightVideo" className="ga-highlight__bg__video">
                <source src={`${HighlightBackgroundVideo}#t=1`} type="video/mp4" />
              </video>
            </div>

            <div className="ga-highlight__bars">
              <div className="ga-highlight__bar ga-highlight__bar--1"></div>
              <div className="ga-highlight__bar ga-highlight__bar--2"></div>
              <div className="ga-highlight__bar ga-highlight__bar--3"></div>
              <div className="ga-highlight__bar ga-highlight__bar--4"></div>
              <div className="ga-highlight__bar ga-highlight__bar--5"></div>
              <div className="ga-highlight__bar ga-highlight__bar--6"></div>
              <div className="ga-highlight__bar ga-highlight__bar--7"></div>
            </div>

            <div className="ga-highlight__slogan" dangerouslySetInnerHTML={{ __html: content.content.rendered }} />

          </div>

        </div>

      </section>
    );
  }
};

export default Highlight;
