import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';

import Header from '../header';
import HomePage from '../home';
import NotFound from '../notfound';

import * as ROUTES from '../../constants/routes';

class App extends Component {

  componentDidMount() {
    const loader = document.getElementById('ga-progress-indicator')
      if(loader){
        loader.classList.add('loaded')
        setTimeout(() => {
          loader.outerHTML = ''
        }, 1500)
      }
  }

  render() {
    return (
      <>
        <Router>
          <div className="ga-frame">
            <Header />
            <Switch>
              <Route
                exact path={ROUTES.HOME}
                render={(props) => <HomePage {...props} local='english' />}
              />
              <Route
                exact path={ROUTES.HOME_HU}
                render={(props) => <HomePage {...props} local='hungarian' />}
              />
              <Route
                component={NotFound}
              />
            </Switch>
          </div>
        </Router>
      </>
    )
  }
}
export default App;