 export const metadata = {
  english: {
    title: 'Acconsulting',
    description: 'Accounting. Cloud. Environmental consciousness.',
    keywords: 'accounting, cloud, environmental consciousness',
  },
  hungarian: {
    title: 'Acconsulting',
    description: 'Könyvelés. Cloud. Környezettudatosság.',
    keywords: 'könyvelés, cloud, környezettudatosság',
  }
};